*, *::before, *::after{
  box-sizing: border-box;
}

:root {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.collasping{
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

/* .collapse{
  height: 600px;
} */

.header {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 280px;
  background: #54B689;
  color: #fff;
  overflow-y: auto;
  scrollbar-color: rgba(0,0,0,0.2) #54B689;
  scrollbar-width: thin;
}

.header .blog-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
}

.header .navbar {
  padding: 1.5rem 1rem;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
}
@media (max-width: 991.98px){

.header {
    position: static;
    width: inherit;
    height: auto;
}
}
@media (max-width: 991.98px){
.header .blog-name {
    width: 100%;
    position: absolute;
    left: 0;
    top: 1.2rem;
}
}
@media (max-width: 991.98px){
.header .navbar {
    padding: 1rem;
}
}

.filters {
  list-style: none;
  font-size: 0.875rem;
}

.filters .type {
  display: inline-block;
  padding: 0.275rem 0.25rem;
  cursor: pointer;
  margin-right: 2rem;
  color: #8f8f8f;
  font-weight: bold;
}

.filters .type.active {
  border-top: 2px solid #54B689;
  color: #54B689;
  font-weight: bold;
}


.single-col-max-width {
  max-width: 820px;
}

.cta-section .heading {
  font-size: 2rem;
  font-weight: bold;
}

.skill-icons{
  overflow: visible;
  box-sizing: content-box;
  width: 35px;
  height: auto;
  vertical-align: -0.125em;
}

a.theme-link {
  color: #292929;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(41,41,41,0.3);
  text-decoration-color: rgba(41,41,41,0.3);
}

.resume-section-heading:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 100%;
  background: #54B689;
  position: absolute;
  left: 0;
  top: 0;
}

.section-title:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 100%;
  background: #54B689;
  position: absolute;
  left: 0;
  top: 0;
}

.social-list a {
  width: 32px;
  height: 32px;
  padding-top: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #54B689;
}

.social-list a:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #4f4f4f;
}

.btn-success{
  background-color: #54B689;
  border: none;
}
.contact-list a{
  color: #54B689;
  margin: auto 15px;
} 

.contact-icons{
  font-size: 1.5em;
} 

.contact-icons:hover{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
} 


.btn {
  font-weight: bold;
  padding: 0.375rem 1rem;
  height: 2.5rem;
  font-size: 1rem;
  outline: none;
}

.btn-outline-secondary{
  color: #fff;
  background: rgba(0,0,0,0.3);
  border-color: transparent;
  font-size: 1rem;
}


.theme-bg-light {
  background: #fafafa !important;
}

.profile-section .profile-image {
  max-width: 160px;
}

.header .nav-item {
  font-weight: bold;
}
/* 
.header .nav-item .nav-link {
  color: rgba(255,255,255,0.8);
} */

.profile-teaser .name {
  font-size: 3rem;
}

.profile-teaser .tagline {
  font-size: 1.5rem;
  font-weight: 300;
}

.profile-teaser .profile-image {
 max-width: 300px;
  height: 300px;
}


@media (min-width: 992px) {
.profile-teaser .profile-image {
    max-width: 250px;
}
}
@media (min-width: 1200px) {
  .profile-teaser .profile-image {
      max-width: 400px;
  }
  }
  
  .section-title {
    font-size: 2rem;
    position: relative;
    padding-left: 1.5rem;
}

.overview-section .item {
  margin-bottom: 2rem;
}

.fa-js-square {
  color: #F1DE4F;
}

.fa-html5 {
  color: #DE6E3C;
}
.fa-css3-alt {
  color: #53A7DC;
}

.fa-sass{
  color: #c253a1;
}

.fa-react {
  color: #62D4FA;
}

.fa-angular{
  color: red;
}

.fa-node-js{

  color: #54B689;
}
.fa-mongodb{
  
  color: #1b6b46;
}
.si-express{
  color:  #FFC107;
}

.fa-npm{
  color: #CB3837;
}

.si-redux{
  color: #764ABC;
}
.si-MUI{
  color: #0081CB;
}

.fa-bootstrap{
  color: #563D7C;
}
.overview-section .item-title {
  font-size: 1rem;
}


.si-ts-square{
  color: #1976d2;
}

.si-cs-round{
  color: #184896;
}

.si-tailwind{
  color: #56a87f;
}

.si-dotnet{
  color: #184896;
}

.si-graphql{
  color:#c253a1;
}
.project-card {
  border-radius: 0;
  background: #fafafa;
  border: 1px solid rgba(0,0,0,0.025);
  position: relative;
  height: 100%;
}

.card-img{
  height: 100%;
  border-radius: 0;
}

.project-card .card-img-holder {
  max-height: 200px;
  overflow: hidden;
  vertical-align: middle;
}

.project-card .card-title {
  font-size: 1.125rem;
}

.project-card .link-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.project-card .link-mask .link-mask-link{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.project-card .link-mask-text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  display: none;
}

.project-card .link-mask-text .btn {
  top: 50%;
  margin-top: -20px;
  position: relative;
}

.filters .type.active {
  border-top: 4px solid #54B689;
  color: #54B689;
  font-weight: bold;
}


.dark-mode-toggle .toggle+.toggle-btn:after {
  left: 0;
  transition: all 0.2s ease-in-out;
  background: rgba(255,255,255,0.4);
  content: "";
  border-radius: 16px;
}

.overview-section .item-desc {
  color: #4f4f4f;
  font-size: 0.875rem;
}

.dark-mode-toggle .toggle+.toggle-btn:after, .dark-mode-toggle .toggle+.toggle-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.header .btn-primary {
  background: rgba(0,0,0,0.3);
  border-color: transparent;
  font-size: 1rem;
}

.main-wrapper {
  margin-left: 280px;
  background: #fff;
}
@media (max-width: 992px) {
  .main-wrapper {
    margin-left: 0;
  }
}
.resume-container {
  max-width: 1140px;
}

.resume-name {
  font-size: 2.75rem;
  font-weight: 900;
  letter-spacing: 0.4rem;
  color: #54B689;
}

.resume-contact {
  border-left: 1px solid rgba(0,0,0,0.08);
  font-size: 0.75rem;
}

a.resume-link {
  color: #4f4f4f;
}



.resume-profile-image {
  max-width: 120px;
}

.resume-section-heading {
  position: relative;
  padding-left: 1rem;
  font-size: 1.125rem;
  letter-spacing: 0.15rem;
  color: #54B689;
}

.resume-aside {
  border-left: 1px solid rgba(0,0,0,0.08);
}


.resume-section-heading {
  position: relative;
  padding-left: 1rem;
  font-size: 1.125rem;
  letter-spacing: 0.15rem;
  color: #54B689;
}
.item-heading{
  justify-content: space-between;
}

.item-meta{
  width: auto;
}

.item-title{
  width: auto;
}
@media (max-width: 768px) {
  .resume-contact {
    border-left: none;
  
  }
  .resume-aside {
    border-top: 1px solid rgba(0,0,0,0.08);
    border-left: none;
  }
  .resume-list{
    padding: 0;
  }
  .item-meta{
    display: flex;
    justify-content: center;
  }
  .resume-section-heading:before {
    display: none;
  }
  .section-title:before {
    display: none
  }
  .item-heading{
    flex-direction: column;
  }
}
.resume-profile-image{
 border-radius: 50%;
}